import React, {useState, useEffect} from 'react';
import AppBar from "@mui/material/AppBar/AppBar";
import {ThemeProvider, Toolbar} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Link as RouterLink, useParams } from 'react-router-dom';
import axios from 'axios';
import Button from "@mui/material/Button";
import {green, pink, red} from "@mui/material/colors";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateRouteForm from "./CreateRouteForm";
import SendForm from "./SendForm";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}



function ViewAscents(props){
    const routeID = props.routeID;
    const [loading, setLoading] = useState(true);
    const [ascents, setAscents] = useState([]);

    useEffect(() => {
        axios.get('/api/ascents/' + routeID).then((response) => {
            console.log(response.data);
            setAscents(response.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err);
        });
    }, []);

    return (
        <>
            {loading ? (
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <CircularProgress size={100} style={{marginTop:'20px'}}/>
                </Grid>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align={'center'}>Lead</TableCell>
                            <TableCell align={'center'}>Sent</TableCell>
                            <TableCell align={'center'}>Flash</TableCell>
                            <TableCell align={'center'}>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ascents.map(ascent => {
                            let date = new Date(ascent.date);
                            let dateString = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
                            return (
                                <TableRow key={ascent._id}>
                                    <TableCell>{dateString}</TableCell>
                                    <TableCell align={'center'}>{ascent.lead ? (
                                        <CheckCircleIcon style={{color: green[500]}}/>
                                    ) : (
                                        <CancelIcon style={{color: red[500]}}/>
                                    )}</TableCell>
                                    <TableCell align={'center'}>{ascent.falls === 0 ? (
                                        <CheckCircleIcon style={{color: green[500]}}/>
                                    ) : (
                                        ascent.falls === 4 ? (
                                            <CancelIcon style={{color: red[500]}}/>
                                        ) : (
                                            <Typography>{ascent.falls}</Typography>
                                        )
                                    )}</TableCell>
                                    <TableCell align={'center'}>{ascent.flash ? (
                                        <CheckCircleIcon style={{color: green[500]}}/>
                                    ) : (
                                        <CancelIcon style={{color: red[500]}}/>
                                    )}</TableCell>
                                    <TableCell align={'center'}>
                                        <RouterLink to={`${ascent._id}`}>
                                            <Button variant={'contained'} color={'primary'} fullWidth={true}>
                                                Edit
                                            </Button>
                                        </RouterLink>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            )}
        </>

    )
}

function ViewFriendAscents(props){
    const routeID = props.routeID;
    const [loading, setLoading] = useState(true);
    const [ascents, setAscents] = useState([]);

    useEffect(() => {
        axios.get('/api/ascents/' + routeID + '/friends').then((response) => {
            console.log(response.data);
            setAscents(response.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err);
        });
    }, []);

    return (
        <>
            {loading ? (
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <CircularProgress size={100} style={{marginTop:'20px'}}/>
                </Grid>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell align={'center'}>Lead</TableCell>
                            <TableCell align={'center'}>Sent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ascents.map(ascent => {
                            let date = new Date(ascent.date);
                            let dateString = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
                            return (
                                <TableRow key={ascent._id}>
                                    <TableCell>{dateString}</TableCell>
                                    <TableCell>{ascent.user.username}</TableCell>
                                    <TableCell align={'center'}>{ascent.lead ? (
                                        <CheckCircleIcon style={{color: green[500]}}/>
                                    ) : (
                                        <CancelIcon style={{color: red[500]}}/>
                                    )}</TableCell>
                                    <TableCell align={'center'}>{ascent.falls === 0 ? (
                                        <CheckCircleIcon style={{color: green[500]}}/>
                                    ) : (
                                        ascent.falls === 4 ? (
                                            <CancelIcon style={{color: red[500]}}/>
                                        ) : (
                                            <Typography>{ascent.falls}</Typography>
                                        )
                                    )}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            )}
        </>

    )
}



function ClimbingRoute(props){
    const wallID = useParams().wallID;
    const routeID = useParams().routeID;


    const [loading, setLoading] = useState(true);
    const [route, setRoute] = useState(null);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        axios.get('/api/route/' + routeID).then((response) => {
            console.log(response.data);
            setRoute(response.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err);
        });
    }, []);

    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <Breadcrumbs aria-label={'breadcrumb'}>
                        <RouterLink to={'/'}>Home</RouterLink>
                        <RouterLink to={'/record/' + wallID}>{wallID}</RouterLink>
                        {loading ? (
                            <Typography color={'textPrimary'}>{routeID}</Typography>
                        ) : (
                            <Typography color={'textPrimary'}>
                                {`${route.difficulty} ${route.setter} (${route.colour})`}
                            </Typography>
                        )}

                    </Breadcrumbs>
                </Toolbar>
            </AppBar>
            <Container maxWidth={'sm'} style={{marginTop:'4px', padding:'0px'}}>
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    {loading ? (<CircularProgress size={100} style={{marginTop:'20px'}}/>) : (
                        <>
                            <AppBar color={'secondary'} position={'sticky'}>
                                <Tabs value={tab} onChange={handleTabChange} centered>
                                    <Tab label={"Record"} id={'simple-tab-0'} aria-controls={'simple-tabpanel-0'}/>
                                    <Tab label={'View'} id={'simple-tab-1'} aria-controls={'simple-tabpanel-1'}/>
                                    <Tab label={'Friends'} id={'simple-tab-2'} aria-controls={'simple-tabpanel-2'}/>
                                    <Tab label={"Edit"} id={'simple-tab-3'} aria-controls={'simple-tabpanel-3'}/>
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tab} index={0}>
                                <SendForm routeID={routeID} setTab={setTab}/>
                            </TabPanel>
                            <TabPanel value={tab} index={1} style={{width:'100%'}}>
                                <ViewAscents routeID={routeID}/>
                            </TabPanel>
                            <TabPanel value={tab} index={2} style={{width:'100%'}}>
                                <ViewFriendAscents routeID={routeID}/>
                            </TabPanel>
                            <TabPanel value={tab} index={3} style={{width:'100%'}}>
                                <Grid
                                    container
                                    direction={'column'}
                                    justify={'flex-start'}
                                    alignItems={'center'}
                                    spacing={1}
                                >
                                    <CreateRouteForm edit={true} wallID={wallID} routeID={routeID}/>
                                </Grid>

                            </TabPanel>

                        </>

                    )}

                </Grid>
            </Container>
        </>
    );
}

export default ClimbingRoute;