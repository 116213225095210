import React, {useEffect, useState} from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material/styles";
import {BrowserRouter as Router, Link, Route, Routes, Navigate} from 'react-router-dom';
import createTheme from "@mui/material/styles/createTheme";
import Loading from "./Loading";
import Login from "./login";
import axios from 'axios';

import {pink} from "@mui/material/colors";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TimelineIcon from '@mui/icons-material/Timeline';

import Walls from "./Walls";
import Wall from "./Wall";
import CreateRoute from "./CreateRoute";
import ClimbingRoute from "./ClimbingRoute";
import History from './History';
import Ascent from "./Ascent";


const theme = createTheme({
    palette:{
        type:'dark',
        primary: {
            main: pink[900],
        },
    },
});

function Loaded(props) {
    const [value, setValue] = React.useState(0);
    return (
        <>
            {value === 0 ? (
                <Routes>
                    <Route path={'/record/:wallID/route/:routeID/:ascentID'} element={<Ascent/>}>
                    </Route>
                    <Route path={'/record/:wallID/route/:routeID'} element={<ClimbingRoute/>}>
                        
                    </Route>
                    <Route path={'/record/:wallID/create'} element={<CreateRoute/>}>
                        
                    </Route>
                    <Route path={'/record/:wallID'} element={<Wall/>}>
                        
                    </Route>
                    <Route path={'/record'} element={<Walls />}>
                        
                    </Route>
					<Route path={'/history'} element={<History/>}>
                        
                    </Route>
                    <Route path={'/'} element={<Navigate to={'/record'}/>}>
                        
                    </Route>
                </Routes>
            ) : (
                <Routes>
                    <Route path={'/history'} element={<History/>}>
                        
                    </Route>
                    <Route path={'/'} element={<Navigate to={'/history'}/>}>
                        
                    </Route>
                </Routes>
            )}

            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
				sx={{
					width: "100%",
					position: 'fixed',
					bottom: '0px'
				}}
                //className={classes.root}
            >
				<Link to={`/record`}><BottomNavigationAction label="Record" icon={<NoteAddIcon/>} /></Link>
                <Link to={`/history`}><BottomNavigationAction label="History" icon={<TimelineIcon />} /></Link>
                
            </BottomNavigation>
        </>


    )
}

function App() {
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        axios.post('/api/login').then((res) => {
            setAdmin(res.data.admin);
            setLoggedIn(true);
            setLoading(false);
        }).catch(() => {
            setLoggedIn(false);
            setLoading(false);
        });
    },[]);

    return (
      <ThemeProvider theme={theme}>
          <Router>
              {loading ? (
                  <Loading/>
              ) : (

                  !loggedIn ? (
                      <Login setLoggedIn={setLoggedIn} setAdmin={setAdmin}/>
                  ) : (
                      <Loaded admin={admin}/>
                      )
              )}

          </Router>
      </ThemeProvider>
    );
}

export default App;
