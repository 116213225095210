import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';

function LoginForm(props){

    const [values, setValues] = useState({
        username: '',
        password: ''
    });
    const [submitting, setSubmitting] = useState(false);

    async function handleSubmit(event){
        event.preventDefault();
        setSubmitting(true);

        let response;
        try{
            response = await axios.post('/api/login', values);
        } catch (error) {
            console.log(error.response);
            alert(`Error: ${error.response.status} - ${error.response.data}`);
            setSubmitting(false);
            return;
        }

        if (response){
            //console.log(response);
            setSubmitting(false);
            props.setLoggedIn(true);
            props.setAdmin(response.data.admin);
        }


    }

    function handleChange(event){
        event.persist();
        setValues({...values, [event.target.name]: event.target.value});
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                fullWidth={true}
                label={'Username'}
                value={values.username}
                onChange={handleChange}
				sx={{
					marginBottom:'20px',
				}}
                name={'username'}
                type={'username'}
                required
            />
            <TextField
                fullWidth={true}
                label={'Password'}
                value={values.password}
                onChange={handleChange}
				sx={{
					marginBottom:'20px',
				}}
                name={'password'}
                type={'password'}
                required
            />
            <Box align={'center'}>
                <Button
                    type={'submit'}
                    color={'primary'}
                    variant={'contained'}
                    size={'large'}
                    align={'center'}
                    disabled={submitting}
                >
                    Login
                </Button>
                {submitting && <CircularProgress size={24} 
				sx={{
					color: 'white',
					position: 'relative',
					top: '7px',
					left: '-45px',
					marginTop: -12,
					marginLeft: -12,
				}}
				/>}
            </Box>
        </form>
    );
}

function Login(props){

    return (
        <Grid
            container
            spacing={0}
            direction={'column'}
            alignItems={'center'}
            justify={'center'}
            style={{height:'80vh'}}
        >
            <LoginForm setLoggedIn={props.setLoggedIn} setAdmin={props.setAdmin}/>
        </Grid>
    );
}

export default Login;