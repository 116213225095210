import React, { useState, useEffect } from 'react';
import { Link as RouterLink, Route, Switch, useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import {Toolbar} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";

import SICGMap from './sicg.png';

function Map(){
    return (
        <>
            <figure style={{
                position: 'relative',
                height:'75vh',
                width:'100%',
                verticalAlign:'middle',
                margin:0,
            }}>
                <svg version={'1.1'} xmlns={'http://www.w3.org/2000/svg'} xmlnsXlink={'http://www.w3.org/1999/xlink'} viewBox={"0 0 1300 2000"}>
                    <image width={'1300'} height={'2000'} xlinkHref={SICGMap}/>
                    <RouterLink to={'/record/left-wall-front'}>
                        <rect x={'0'} y={'1425'} width={'120'} height={'575'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/left-wall-middle'}>
                        <rect x={'0'} y={'735'} width={'120'} height={'689'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/left-wall-back'}>
                        <rect x={'0'} y={'128'} width={'120'} height={'610'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-wall-slab-left'}>
                        <rect x={'0'} y={'0'} width={'186'} height={'128'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-wall-slab-middle'}>
                        <rect x={'186'} y={'0'} width={'215'} height={'97'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-wall-roof'}>
                        <rect x={'396'} y={'0'} width={'186'} height={'176'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-wall-mantle'}>
                        <rect x={'582'} y={'0'} width={'201'} height={'129'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-wall-slab-right'}>
                        <rect x={'781'} y={'0'} width={'235'} height={'84'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-wall-auto-belay'}>
                        <rect x={'1016'} y={'0'} width={'284'} height={'175'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/front-column-left'}>
                        <rect x={'391'} y={'1146'} width={'122'} height={'603'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/front-column-right'}>
                        <rect x={'920'} y={'1286'} width={'127'} height={'335'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/front-column-front'}>
                        <rect x={'512'} y={'1617'} width={'535'} height={'130'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/front-column-back'}>
                        <rect x={'512'} y={'1147'} width={'535'} height={'139'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-column-left'}>
                        <rect x={'391'} y={'333'} width={'122'} height={'486'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-column-right'}>
                        <rect x={'919'} y={'333'} width={'127'} height={'486'} fill={'#e91e63'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-column-front'}>
                        <rect x={'513'} y={'701'} width={'407'} height={'118'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                    <RouterLink to={'/record/back-column-back'}>
                        <rect x={'513'} y={'333'} width={'407'} height={'125'} fill={'#880e4f'} opacity={'100'}/>
                    </RouterLink>
                </svg>
            </figure>
        </>

    )
}

function Walls(){

    // const preventDefault = event => event.preventDefault();
    // const walls = [
    //     'Left Wall Front',
    //     'Left Wall Middle',
    //     'Left Wall Back',
    //     'Back Wall Slab Left',
    //     'Back Wall Slab Middle',
    //     'Back Wall Roof',
    //     'Back Wall Mantle',
    //     'Back Wall Slab Right',
    //     'Back Wall Auto Belay',
    //     'Front Column Left',
    //     'Front Column Right',
    //     'Front Column Front',
    //     'Front Column Back',
    //     'Back Column Left',
    //     'Back Column Right',
    //     'Back Column Front',
    //     'Back Column Back',
    // ];

    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <Breadcrumbs aria-label={'breadcrumb'}>
                        <Typography color={'textPrimary'}>Home</Typography>
                    </Breadcrumbs>
                </Toolbar>
            </AppBar>
            <CssBaseline/>
            <Container maxWidth={'sm'} style={{marginTop:'10px'}}>
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <Map/>
                    {/*{walls.map(wall => (*/}
                    {/*    <Grid key={wall.replace(/ /g, "-").toLowerCase()} item xs={12} style={{width:'100%'}}>*/}
                    {/*        <RouterLink to={wall.replace(/ /g, "-").toLowerCase()}>*/}
                    {/*            <Button variant={'contained'} color={'primary'} fullWidth={true}>*/}
                    {/*                {wall}*/}
                    {/*            </Button>*/}
                    {/*        </RouterLink>*/}
                    {/*    </Grid>*/}
                    {/*))}*/}
                </Grid>
            </Container>
        </>
    )
}

export default Walls;