import React, { useState, useEffect } from 'react';
import axios from "axios";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {pink} from "@mui/material/colors";
import { Link as RouterLink, useParams, Navigate } from 'react-router-dom';
import {Checkbox, FormControlLabel} from "@mui/material";
import Typography from "@mui/material/Typography";

function CreateRouteForm(props){
    const [difficulty, setDifficulty] = useState(20);
    const [colour, setColour] = useState('black');
    const [setter, setSetter] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [archived, setArchived] = useState(false);

    const handleArchiveChange = (event) => {
        //event.persist();
        console.log(event.target.checked);
        setArchived(event.target.checked);
    };

    const handleSetterChange = (event) => {
        //event.persist();
        setSetter(event.target.value);
    };

    const handleDifficultyChange = (event) => {
        //event.persist();
        setDifficulty(event.target.value);
    };

    const handleColourChange = (event) => {
        //event.persist();
        setColour(event.target.value);
    };

    useEffect(() => {
        if (props.edit) {
            axios.get('/api/route/' + props.routeID).then((response) => {
                console.log(response.data);
                let route = response.data;
                setDifficulty(route.difficulty);
                setColour(route.colour);
                setSetter(route.setter);
                setArchived(route.archived);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                alert(err);
            });
        }

    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('submitting');

        if (!submitting) {
            setSubmitting(true);
            let data = {
                difficulty: difficulty,
                colour: colour,
                setter: setter,
                wall: props.wallID,
                archived: archived,
            };
            let response;
            try {
                if (props.edit) {
                    response = await axios.post('/api/route/' + props.routeID, data);
                } else {
                    response = await axios.post('/api/route', data);
                }
            } catch (error) {
                console.log(error.response);
                alert(error);
                setSubmitting(false);
                return;
            }

            if (response) {
                console.log(response);
                setSubmitting(false);
                setSuccess(true);
            }
        }
    };

    return (
        <>
            {props.edit && loading ? (
                <CircularProgress size={100} style={{marginTop:'20px'}}/>
            ) : (
                <form style={{width:'100%'}} onSubmit={handleSubmit}>
                    <Grid container direction={'column'} align={'flex-start'} justify={'flex-start'}>
                        <FormControl 
						sx={{
							marginBottom:'20px'
						}}
						>
                            <InputLabel id={'difficulty-label'}>Difficulty</InputLabel>
                            <Select
                                labelId={'difficulty-label'}
                                id={'difficulty'}
                                value={difficulty}
                                onChange={handleDifficultyChange}
                                style={{width:'100%'}}
                            >
                                <MenuItem value={14}>{"<=14"}</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={16}>16</MenuItem>
                                <MenuItem value={17}>17</MenuItem>
                                <MenuItem value={18}>18</MenuItem>
                                <MenuItem value={19}>19</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={21}>21</MenuItem>
                                <MenuItem value={22}>22</MenuItem>
                                <MenuItem value={23}>23</MenuItem>
                                <MenuItem value={24}>24</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={26}>26</MenuItem>
                                <MenuItem value={27}>27</MenuItem>
                                <MenuItem value={28}>28</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl 
						sx={{
							marginBottom:'20px'
						}}
						>
                            <InputLabel id={'colour-label'}>Colour</InputLabel>
                            <Select
                                labelId={'colour-label'}
                                id={'colour'}
                                value={colour}
                                onChange={handleColourChange}
                                style={{width:'100%'}}
                            >
                                <MenuItem value={'black'}>Black</MenuItem>
                                <MenuItem value={'blue'}>Blue</MenuItem>
                                <MenuItem value={'brown'}>Brown</MenuItem>
                                <MenuItem value={'green-aqua'}>Green (Aqua)</MenuItem>
                                <MenuItem value={'green-dark'}>Green (Dark)</MenuItem>
                                <MenuItem value={'green-fluoro'}>Green (Fluoro)</MenuItem>
                                <MenuItem value={'green-lime'}>Green (Lime)</MenuItem>
                                <MenuItem value={'orange'}>Orange</MenuItem>
                                <MenuItem value={'pink'}>Pink</MenuItem>
                                <MenuItem value={'red'}>Red</MenuItem>
                                <MenuItem value={'tan'}>Tan</MenuItem>
                                <MenuItem value={'white'}>White</MenuItem>
                                <MenuItem value={'yellow'}>Yellow</MenuItem>
                                <MenuItem value={'other'}>Other</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullwidth={'true'}
                            label={'Setter'}
                            style={{marginBottom:'20px'}}
                            value={setter || ''}
                            name={'setter'}
                            onChange={handleSetterChange}
                            required
                        />
                        {props.edit && (<FormControlLabel
                            control={<Checkbox
                                checked={archived}
                                onChange={handleArchiveChange}
                                value={'archived'}
                            />}
                            label={ <Typography color={'textPrimary'}>Archived</Typography> }
                        />)}
                        <Box align={'center'}>
                            <Button
                                type={'submit'}
                                color={'primary'}
                                variant={'contained'}
                                size={'large'}
                                align='center'
                                disabled={submitting}
                            >
                                Submit
                            </Button>
                            {submitting && <CircularProgress size={24} style={{
                                color: pink[500],
                                position: 'relative',
                                top: '7px',
                                left: '-58px',
                                marginTop: -12,
                                marginLeft: -12,
                            }}/>}
                        </Box>
                    </Grid>
                    {success && (
                        <Navigate to={{pathname:`/record/${props.wallID}`}}/>
                    )}
                </form>
            )}

        </>
    )
}

export default CreateRouteForm;