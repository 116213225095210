import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

function Loading(){
    return (
        <Grid
            container
            spacing={0}
            direction={'row'}
            alignItems={'center'}
            justify={'center'}
            style={{paddingTop:'50px'}}
        >
            <Grid item>
                <CircularProgress size={100}/>
            </Grid>
        </Grid>
    )
}

export default Loading;