import React, { useState, useEffect } from 'react';
import { Link as RouterLink, Route, Switch, useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import {Toolbar} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {green, red} from "@mui/material/colors";
import CancelIcon from '@mui/icons-material/Cancel';

function History () {
    const [loading, setLoading] = useState(true);
    const [ascents, setAscents] = useState([]);

    useEffect(() => {
        axios.get('/api/ascents/').then((response) => {
            console.log(response.data);
            setAscents(response.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err);
        });
    }, []);

    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <Breadcrumbs aria-label={'breadcrumb'}>
                        <Typography color={'textPrimary'}>Home</Typography>
                    </Breadcrumbs>
                </Toolbar>
            </AppBar>
            <CssBaseline/>
            <Container maxWidth={'sm'} style={{marginTop:'10px'}}>
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    {loading ? (
                        <Grid
                            container
                            direction={'column'}
                            justify={'flex-start'}
                            alignItems={'center'}
                            spacing={1}
                        >
                            <CircularProgress size={100} style={{marginTop:'20px'}}/>
                        </Grid>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Route</TableCell>
                                    <TableCell>Wall</TableCell>
                                    <TableCell>Lead</TableCell>
                                    <TableCell align={'center'}>Sent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ascents.map(ascent => {
                                    let date = new Date(ascent.date);
                                    let dateString = `${date.getDate()}/${date.getMonth()+1}`;
                                    let routeString = `${ascent.route.difficulty} ${ascent.route.colour}`;
                                    return (
                                        <TableRow key={ascent._id}>
                                            <TableCell>{dateString}</TableCell>
                                            <TableCell>{routeString}</TableCell>
                                            <TableCell>{ascent.route.wall}</TableCell>
                                            <TableCell align={'center'}>{ascent.lead ? (
                                                <CheckCircleIcon style={{color: green[500]}}/>
                                                ) : (
                                                <CancelIcon style={{color: red[500]}}/>
                                            )}</TableCell>
                                            <TableCell align={'center'}>{ascent.falls === 0 ? (
                                                <CheckCircleIcon style={{color: green[500]}}/>
                                            ) : (
                                                ascent.falls === 4 ? (
                                                    <CancelIcon style={{color: red[500]}}/>
                                                ) : (
                                                    <Typography>{ascent.falls}</Typography>
                                                )
                                            )}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                </Grid>
            </Container>
        </>
    )
}

export default History;