import React, {useState, useEffect} from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar/AppBar";
import {Toolbar} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SendForm from "./SendForm";
import CreateRouteForm from "./CreateRouteForm";
import axios from 'axios';

function Ascent () {
    const wallID = useParams().wallID;
    const routeID = useParams().routeID;
    const ascentID = useParams().ascentID;
    const [loading, setLoading] = useState(true);
    const [ascent, setAscent] = useState(null);
    const [dateString, setDateString] = useState(ascentID);

    useEffect(() => {
        axios.get('/api/ascent/' + ascentID).then((response) => {
            //console.log(response.data);
            setAscent(response.data);
            const date = new Date(response.data.date);
            setDateString(`${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err);
        });
    }, []);


    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <Breadcrumbs aria-label={'breadcrumb'}>
                        <RouterLink to={'/'}>Home</RouterLink>
                        <RouterLink to={'/record/' + wallID}>{wallID}</RouterLink>
                        {loading ? (
                            <RouterLink to={'/record/' + wallID + '/route/' + routeID}>{routeID}</RouterLink>
                        ) : (
                            <RouterLink to={'/record/' + wallID + '/route/' + routeID}>
                                {`${ascent.route.difficulty} ${ascent.route.setter} (${ascent.route.colour})`}
                            </RouterLink>
                        )}
                        <Typography color={'textPrimary'}>{dateString}</Typography>

                    </Breadcrumbs>
                </Toolbar>
            </AppBar>
            <Container maxWidth={'sm'} style={{marginTop:'4px'}}>
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    {loading ? (<CircularProgress size={100} style={{marginTop:'20px'}}/>) : (
                        <>
                            <Typography variant={'h1'} color={'textPrimary'}>Edit Ascent</Typography>
                            <SendForm ascent={ascent}/>
                        </>

                    )}

                </Grid>
            </Container>
        </>
    )
}

export default Ascent;