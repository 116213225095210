import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import {Toolbar} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';
import {createMuiTheme, ThemeProvider} from "@mui/material/styles";
import {red, grey, lightBlue, brown, orange, pink, yellow, lightGreen} from '@mui/material/colors';

const theme = {
    'red': createMuiTheme({
        palette: {
            primary: {
                main: red[900],
            }
        }
    }),
    black: createMuiTheme({
        palette: {
            primary: {
                main: grey[900],
            }
        }
    }),
    'blue': createMuiTheme({
        palette: {
            primary: {
                main: lightBlue[500],
            }
        }
    }),
    'brown': createMuiTheme({
        palette: {
            primary: {
                main: brown[500],
            }
        }
    }),
    'green-aqua': createMuiTheme({
        palette: {
            primary: {
                main: '#00c853',
            }
        }
    }),
    'green-dark': createMuiTheme({
        palette: {
            primary: {
                main: lightGreen[900],
            }
        }
    }),
    'green-fluoro': createMuiTheme({
        palette: {
            primary: {
                main: '#76ff03',
            }
        }
    }),
    'green-lime': createMuiTheme({
        palette: {
            primary: {
                main: '#ccff90',
            }
        }
    }),
    'orange': createMuiTheme({
        palette: {
            primary: {
                main: orange[500],
            }
        }
    }),
    'pink': createMuiTheme({
        palette: {
            primary: {
                main: pink[500],
            }
        }
    }),
    'tan': createMuiTheme({
        palette: {
            primary: {
                main: '#ffe57f',
            }
        }
    }),
    'white': createMuiTheme({
        palette: {
            primary: {
                main: grey[50],
            }
        }
    }),
    'yellow': createMuiTheme({
        palette: {
            primary: {
                main: yellow[500],
            }
        }
    }),
    'other': createMuiTheme({
        palette: {
            primary: {
                main: grey[900],
            }
        }
    }),
};

function Wall(){
    const wallID = useParams().wallID;
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(`hit: ${wallID}`);
        axios.get('/api/routes/' + wallID).then((response) => {
            console.log(response.data);
            setRoutes(response.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err);
        });
    }, []);

    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <Breadcrumbs aria-label={'breadcrumb'}>
                        <RouterLink to={'/'}>Home</RouterLink>
                        <Typography color={'textPrimary'}>{wallID}</Typography>
                    </Breadcrumbs>
                </Toolbar>
            </AppBar>
            <Container maxWidth={'sm'} style={{marginTop:'10px'}}>
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    {loading ? (<CircularProgress size={100} style={{marginTop:'20px'}}/>) : (
                        <>
                            {routes.map((route) => {

                                const approved = route.approved ? "" : "* ";

                                return (
                                    <Grid key={route._id} item xs={12} style={{width:'100%'}}>
                                        <RouterLink to={`/record/${wallID}/route/${route._id}`}>
                                            <ThemeProvider theme={theme[route.colour]}>
                                                <Button variant={'contained'} color={'primary'} fullWidth={true}>
                                                    {approved + route.difficulty + " " + route.setter}
                                                </Button>
                                            </ThemeProvider>
                                        </RouterLink>
                                    </Grid>
                                )
                            })}
                            <Grid key={'create'} item xs={12} style={{width:'100%', paddingTop:'20px'}}>
                                <RouterLink to={`create`}>
                                    <Button variant={'contained'} color={'primary'} fullWidth={true}>
                                        Create
                                    </Button>
                                </RouterLink>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Container>

        </>
    )
}

export default Wall;