import React, {useState, useEffect} from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
//import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@mui/lab";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {pink} from "@mui/material/colors";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function SendForm(props){


    const [submitting, setSubmitting] = useState(false);
    const [lead, setLead] = useState('topRope');
    const [send, setSend] = useState(0);
    const [date, setDate] = useState(new Date());
    const [flash, setFlash] = useState('no');
    const [success, setSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        if (props.ascent) {
            console.log(props.ascent);
            setLead(props.ascent.lead ? ('lead') : ('topRope'));
            setSend(props.ascent.falls);
			let doge = new Date(props.ascent.date);
            setDate(doge);
            setFlash(props.ascent.flash ? ('yes') : ('no'));
        }
    }, []);



    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('submitting');
        //setSubmitting(true);
        if (!submitting) {
            setSubmitting(true);

            let data = {
                lead: lead === 'lead',
                falls: send,
                date: date,
                flash: flash === 'yes',
                route: props.routeID,
            };
            console.log(data);

            let response;
            try {
                if (props.ascent){
                    response = await axios.patch('/api/ascent/' + props.ascent._id, data)
                } else {
                    response = await axios.post('/api/ascent', data)
                }
            } catch (error) {
                console.log(error.response);
                alert(error);
                setSubmitting(false);
                return;
            }

            if (response) {
                console.log(response);
                setSuccess(true);
                setSubmitting(false);
                if (!props.ascent) {
                    props.setTab(1);
                }
                //setSuccess(true);
            }

        }

    };

    const handleDelete = async () => {
        if (!confirm){
            setConfirm(true);
        } else {
            let response;
            try {
                response = await axios.delete('/api/ascent/' + props.ascent._id);
            } catch (error) {
                console.log(error.response);
                alert(error);
                setSubmitting(false);
                return;
            }

            if (response){
                setSubmitting(false);
                setDeleteSuccess(true);
            }

        }
    };

    const handleLeadChange = (event) => {
        event.persist();
        setSuccess(false);
        setLead(event.target.value);
    };
    const handleSendChange = (event) => {
        event.persist();
        setSuccess(false);
        setSend(parseInt(event.target.value));
    };
    const handleDateChange = event => {
        setDate(event);
        setSuccess(false);
    };
    const handleFlashChange = event => {
        setFlash(event.target.value);
        setSuccess(false);
    };

    return (
        <form style={{width:'100%'}} onSubmit={handleSubmit}>
            <Grid container direction={'column'} align={'flex-start'} justify={'flex-start'}>
                <FormControl component={'fieldset'} sx={{marginBottom:'20px'}}>
                    <RadioGroup name={'lead'} value={lead} onChange={handleLeadChange} row={true}>
                        <FormControlLabel value={'lead'} control={<Radio/>} label={"Lead"} style={{color:'#fff'}} />
                        <FormControlLabel value={'topRope'} control={<Radio/>} label={"Top Rope"}  style={{color:'#fff'}}/>
                    </RadioGroup>
                </FormControl>
                <FormControl component={'fieldset'} sx={{marginBottom:'20px'}}>
                    <RadioGroup name={'send'} value={send} onChange={handleSendChange} row={true}>
                        <FormControlLabel value={0} control={<Radio/>} label={"Send"} style={{color:'#fff'}} />
                        <FormControlLabel value={1} control={<Radio/>} label={"1"}  style={{color:'#fff'}}/>
                        <FormControlLabel value={2} control={<Radio/>} label={"2"}  style={{color:'#fff'}}/>
                        <FormControlLabel value={3} control={<Radio/>} label={"3+"}  style={{color:'#fff'}}/>
                        <FormControlLabel value={4} control={<Radio/>} label={"DNF"}  style={{color:'#fff'}}/>
                    </RadioGroup>
                </FormControl>
                <FormControl component={'fieldset'} sx={{marginBottom:'20px'}}>
                    <RadioGroup name={'flash'} value={flash} onChange={handleFlashChange} row={true}>
                        <FormControlLabel value={'yes'} control={<Radio/>} label={"Flash"} style={{color:'#fff'}} />
                        <FormControlLabel value={'no'} control={<Radio/>} label={"Send"}  style={{color:'#fff'}}/>
                    </RadioGroup>
                </FormControl>
				
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker 
						id="date-picker-dialog"
                        label="Date picker dialog"
                        format="dd/MM/yyyy"
                        value={date}
                        onChange={handleDateChange}
					/>
				</LocalizationProvider>
                
                <Box align={'center'}>
                    <Button
                        type={'submit'}
                        color={'primary'}
                        variant={'contained'}
                        size={'large'}
                        align='center'
                        disabled={submitting || deleteSuccess}
                    >
                        {props.ascent ? (success ? 'Success!' : 'Edit') : ('Submit')}
                    </Button>
                    {submitting && <CircularProgress size={24} style={{
                        color: pink[500],
                        position: 'relative',
                        top: '7px',
                        left: '-58px',
                        marginTop: -12,
                        marginLeft: -12,
                    }}/>}
                </Box>
                {props.ascent && (
                    <Box align={'center'} style={{paddingTop:'50px'}}>
                        <Button
                            type={'button'}
                            color={'primary'}
                            variant={'contained'}
                            size={'large'}
                            align='center'
                            disabled={submitting || deleteSuccess}
                            onClick={handleDelete}
                        >
                            {deleteSuccess ? ('Success!') : (confirm ? 'Confirm?' : 'Delete')}
                        </Button>
                        {submitting && <CircularProgress size={24} style={{
                            color: pink[500],
                            position: 'relative',
                            top: '7px',
                            left: '-58px',
                            marginTop: -12,
                            marginLeft: -12,
                        }}/>}
                    </Box>
                )}

            </Grid>
        </form>
    )
}

export default SendForm;