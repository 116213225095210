import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar/AppBar";
import {Toolbar} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import CreateRouteForm from "./CreateRouteForm";

function CreateRoute(){
    const wallID = useParams().wallID;
    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <Breadcrumbs aria-label={'breadcrumb'}>
                        <RouterLink to={'/'}>Home</RouterLink>
                        <RouterLink to={'/record/' + wallID}>{wallID}</RouterLink>
                        <Typography color={'textPrimary'}>Create</Typography>
                    </Breadcrumbs>
                </Toolbar>
            </AppBar>
            <Container maxWidth={'sm'} style={{marginTop:'10px'}}>
                <Grid
                    container
                    direction={'column'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <Typography variant={'h1'} color={'textPrimary'}>Add</Typography>
                    <CreateRouteForm wallID={wallID}/>
                </Grid>
            </Container>

        </>
    )
}

export default CreateRoute;